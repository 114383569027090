.small-text {
    font-size: 0.8rem;
}

.carousel-card {
    overflow: auto;
    max-width: 100% !important;
}

.carousel-content {
    max-width: inherit !important;
    max-height: inherit !important;
    overflow: auto;
}

.carousel-header {
    text-transform: uppercase;
    font-weight: bolder;
}

.gray {
    color: gray;
}

.calendar-button:hover {
    transform: scale(1.05);
    color: var(--primary-color) !important;
}

.view-icon {
    width: 100%;
    cursor: pointer;
}