.line-chart{
    width: 100% !important;
    height: 100% !important;
}

.custom-divider {
    margin-top: 1em !important;
    margin-bottom: 1em !important;
    margin-left: -1em !important;
}

.primary-color {
    color: var(--primary-color);
}

.ant-btn-primary {
    background-color: var(--primary-color);
}

.ant-btn-primary:disabled:hover {
    background-color: transparent !important;
    transform: scale(1.1);
}

.ant-btn-primary:hover {
    background-color: var(--primary-color) !important;
    transform: scale(1.1);
}

.ant-layout-sider {
    overflow-y: scroll;
    background-color: var(--secondary-color) !important;
}

.ant-upload-drag {
    height: 11vh !important;
}

.ant-input-number,
.ant-picker {
    width: 100%;
}

.ant-col {
    padding: 0px 1px;
}

.ant-select,
.ant-select-selector,
.ant-select-selection-search,
.ant-select-selection-item,
.ant-select-selection-search-input {
    text-align: left !important;
}

.ant-select-selector .ant-select-selection-item  {
    display: flex !important;
    justify-content: left !important;
    text-align: left !important;
}

.ant-layout-content {
    padding: 3%;
}

.upload-icon {
    transform: scale(2);
}

.ql-toolbar {
    border-radius: 0.5em 0.5em 0 0 !important;
}

.ql-container {
    border-radius: 0 0 0.5em 0.5em !important;
}

.ant-divider {
    margin: 2% 0 !important;
}

.danger {
    color: red !important;
}

.ant-dropdown-trigger {
    border: 0.5px solid rgb(234, 225, 225);
    padding: 5% 10%;
    border-radius: 0.2em;
}
.ant-dropdown-link {
    text-decoration: none;
    color: inherit;
}

.profile-pic-container {
    display: flex !important;
    justify-content: center !important;
}

.profile-picture {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.underlined-text {
    text-decoration: underline;
    margin: 0 2px;
}

.white-text {
    color: white !important;
}

.invisible-text {
    color: transparent;
}

.black-divider {
    background-color: black !important;
    border-color: black !important;
}

.anchor {
    text-decoration: none !important;
    color: var(--primary-color) !important;
    font-weight: bolder;
}

.anchor:hover {
    float: inherit !important;
}

.ant-collapse, .custom-collapse {
    border: none !important;
}

.ant-collapse-item {
    border-radius: 0.5em !important;
    background-color: var(--secondary-color);
    margin: 1% 0;
}

.ant-collapse-content-active {
    border: 1px solid var(--secondary-color);
    border-radius: 0 0 0.5em 0.5em;
}

.ant-modal-body .ant-form-item {
    padding-bottom: 0 !important;
    margin-bottom: 10pt !important;
}

.ant-modal-body .ant-col {
    padding-bottom: 0 !important;
    margin-bottom: 0 !important;
}


.ant-select-selection-item {
    display: flex;
    justify-content: center;
  }
  
  .ant-list-item:hover {
    background-color: #f8f8f8;
    cursor: pointer;
  }
  
  .custom-card {
    border-radius: 10px;
    background-color: var(--secondary-color);
    text-align: center;
  }
  
  .pointer-cursor {
    cursor: pointer;
  }
  
  .pink-bg {
    background-color: pink !important;
  }

  .secondary-bg {
    background-color: var(--secondary-color) !important;
  }
  
.center {
    justify-content: center !important;
}

.tiny-text {
    font-size: 60%;
}

.larger-text {
    font-size: 140%;
}

.italic {
    font-style: italic;
}

.uppercase {
    text-transform: uppercase !important;
}

.align-right {
    text-align: right !important;
}

.red {
    color: red;
}

.bir-form .ant-form-item-label {
    text-align: left !important;
    white-space: pre-wrap !important;
}

.default-bg {
    border: 1px solid rgb(221, 215, 215);
    border-radius: 0.5em;
    padding: 2% !important;
  }

  .ant-input-disabled, 
  .ant-select-disabled .ant-select-selection-item,
  .ant-picker-disabled .ant-picker-input input {
    font-weight: 500 !important;
    color: rgb(38, 36, 36) !important;
  }

  .ant-tabs-tab-active {
    background-color: var(--primary-color) !important;
    .ant-tabs-tab-btn {
        color: white !important;
        font-weight: bold;
    }
  }

  .ant-upload-list-item-container {
        background-color: rgb(253, 253, 171);
        padding: 1%;
        padding-top: 0 !important;
        border: 1px solid gold;
        border-radius: 0.5em;
        margin-top: 1%;

        .ant-upload-list-item {
            margin-top: 0 !important;
        }
  }

  .no-underline a {
    text-decoration: none !important;
  }
