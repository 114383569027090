

.payroll-table-wrapper {
    width: 100% !important;
    height: 70vh !important;
    overflow: auto;
}

.payroll-table-wrapper .ant-table-cell .ant-input {
    width: 8vw !important;
}

/* sticky first row or headers */
.payroll-table-wrapper .ant-table-thead {
    top: 0;
    position: sticky;
    z-index: 4;
}
/* sticky first row and column header */
.payroll-table-wrapper .ant-table-thead .ant-table-cell:first-child {
    top: 0;
    left: 0;
    position: sticky;
    z-index: 2 !important;
}

/* sticky first column */
.payroll-table-wrapper .ant-table-tbody tr .ant-table-cell:first-child {
    left: 0;
    position: sticky;
    z-index: 3 !important;
    background-color: white;
}

.payroll-table-wrapper .ant-table-tbody tr .ant-table-cell:first-child .ant-input {
    width: auto !important;
}

.payroll-table-wrapper .ant-table-thead tr .ant-table-cell:last-child div,
.payroll-table-wrapper .ant-table-tbody tr .ant-table-cell:last-child .ant-input,
.payroll-table-wrapper .ant-table-thead tr .ant-table-cell:last-child(2) div,
.payroll-table-wrapper .ant-table-tbody tr .ant-table-cell:last-child(2) .ant-input {
    width: 15vw !important;
}

.no-vertical-scroll {
    overflow-x: hidden;
}

.no-style-btn, .no-style-btn a {
    text-decoration: none !important;
    background-color: transparent;
    border: none;
    padding: 0;
    font-style: none !important;
}

.ant-btn-primary .no-style-btn, .no-style-btn a:hover {
  color: white !important;
}

.no-margin-bottom {
    margin-bottom: 0 !important;
}

.payslip-logo {
    width: 10% ;
    padding: 0 ;
}

.contract-container {
    background-color: white;
    padding: 2% 5%;
}

  .sharp-edge {
    border-radius: 0 !important;
  }
  
  .bordered {
    border: 1px solid  rgb(171, 168, 168);
    /* border-color: rgb(171, 168, 168) !important; */
  }

  .center-text {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  .ant-typography {
    margin: 0 !important;
    color: var(--text-color) !important;
  }

  .border-right {
    border-right: 1px solid rgb(171, 168, 168) ;
  }

  .primary-bg {
    background-color: var(--primary-color);
  }

  .secondary-bg {
    background-color: var(--secondary-color);
  }

  .table-header-only-borders {
    border-collapse: collapse;
  }
  
  .table-header-only-borders th {
    border: 1px solid gray;
  }
  
  .table-header-only-borders td {
    border: none;
  }

  .bordered-body, .bordered-body td {
    border: 1px solid gray !important;
}

.stripe-table tbody tr:nth-child(even) {
    background-color: #f2f2f2;
  }

  .loader-icon {
    width: 300% !important;
    transform: scale(3) !important;
  }

  .sss-logo {
    width: 40% !important;
  }

  .bw-cells {
    background-color: rgba(0, 0, 0, 0.516) !important;
    color: white;
}

.bw-cells th, .bw-cells td {
  border:1px solid black !important;
}

.view-page-logo {
  width: 100%;
}

.payslip-pdf,
.payslip-pdf .ant-col,
.payslip-pdf .ant-row {
  font-size: 12px !important;
}

.bold-cell td {
  font-weight: bold !important;
}

.no-display {
  display: none !important;
}

.confidential-mark {
  position: absolute;
  top: 50% !important;
  left: 50% !important;

  font-size: 8vw;
  font-weight: 100;
  color: rgb(187, 182, 182);
  transform: translate(-50%, -50%) rotate(-30deg);
  opacity: 0.2;
  font-family: 'Stencil', sans-serif;
}

.pdf-confidential-mark {
  position: fixed;
  top: 60%;
  left: 50%;

  font-size: 6vw;
  font-weight: 100;
  color: rgb(187, 182, 182);
  transform: translate(-50%, -50%) rotate(-30deg);
  opacity: 0.2;
  font-family: 'Stencil', sans-serif;
}

.view-payslip-container {
  padding: 5%;
}

@media screen {
    .printOnly {
        display: none;
    }

    .always-hidden {
        display: none;
    }
}

@page {
    margin: 80px !important;
    font-size: 40%;
}

@media print {
    .contract-container {
        padding: 0 !important;
    }

    .ant-table-pagination {
        display: none !important;
    }

    .ant-table-content {
        font-size: 10px !important;
    }

    .print-report-page {
        padding: 5% 0 !important;
    }

    .print-report-page article {
        font-size: 2vw !important;
    }

    .noPrint {
        display: none !important;
    }

    .payslip-pdf,
    .payslip-pdf .ant-col,
    .payslip-pdf .ant-row {
      font-size: 10px !important;
      font-family: var(--primary-font-medium) !important;
    }

    .confidential-mark {
      font-size: 15vw !important;
    }
}
