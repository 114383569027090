

.ant-picker-calendar .ant-picker-content thead tr th {
    text-align: start;
    padding: 1% !important;
    border: 1px solid rgb(242, 237, 237);
    font-family: var(--primary-font-medium);
}

.ant-picker-calendar .ant-picker-content tbody tr td {
    text-align: start;
    border: 1px solid rgb(242, 237, 237);
}

.events .li::marker {
    display: none !important;
}

.ant-picker-calendar.ant-picker-calendar-full .ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-calendar-date {
    background-color: var(--secondary-color) ;
}

.ant-picker-calendar.ant-picker-calendar-full .ant-picker-calendar-date-today .ant-picker-calendar-date-value {
    background-color: var(--primary-color);
    color: white !important;
    width: fit-content;
    border-radius: 50%;
    padding: 0 4%;
    font-size: 100%;
}

.ant-picker-calendar-date-content .ant-badge {
    padding: 0 4%;
    border-radius: 0.2em;
    width: 100%;
}

.ant-picker-calendar-date-content .ant-badge-status-text {
    margin-inline-start: 0 !important;
    font-size: 80% !important;
}

.ant-picker-calendar-date-content .ant-badge-status-dot {
    display: none !important;
}

.holiday-badge {
    background-color: #A6FF96;
}

.leave-badge {
    background-color: #D2E0FB;
}

.wfa-badge {
    background-color: #C1F2B0;
}

.announcement-badge {
    background-color: #FFB996;
}

.memo-badge {
    background-color: #FFEAA7;
}

.birthday-badge {
    background-color: #FDFFAB;
}

.anniversary-badge {
    background-color: #FFCF81;
}

