.main-content {
    overflow-y: scroll; /* Enable vertical scrolling */
    background-color: #fff;
  }
  
  /* Optional: Customize the scrollbar appearance */
  .main-content::-webkit-scrollbar {
    width: 5px; /* Adjust the width as needed */
  }
  
  .main-content::-webkit-scrollbar-thumb {
    background-color: #888; /* Adjust the color as needed */
  }
  
  .main-content::-webkit-scrollbar-thumb:hover {
    background-color: #555; /* Adjust the hover color as needed */
  }

  .form-content {
    padding-top: 30px;
    padding-right: 50px;
    padding-left: 50px;
  }

  .navbar-menu-container,
  .navbar-menu-container .ant-menu-submenu .ant-menu-submenu-title {
    background-color: var(--primary-color);
    color: white;
  }

  .navbar-menu-container .ant-menu-item-selected {
    color: var(--text-color);
    background-color: var(--secondary-color);
  }

  .navbar-menu-container .ant-menu-item:hover {
    color: var(--text-color) !important;
    background-color: white !important;
  }

  .navbar-logo {
    width: 70%;
    transform: scale(1);
    margin: 2% 0;
  }

  .ant-layout-sider-children .ant-btn-icon {
    color: var(--text-color) !important;
  }

  .ant-dropdown-trigger {
    padding: 0 4% !important;
  }

  .head-buttons .ant-btn-primary {
    width: 100% !important;
  }

  /* CSS for the Navbar component */
.menu-cont {
  background-color: var(--primary-color) !important;
  .ant-menu-item {
    height: 40px; /* Set the desired height */
  }

  .ant-menu-sub {
    margin-bottom: 8px;
  }

  .support-icon {
    transform: scale(0.7) !important;
  }
}

.white-bg {
  background-color: white !important;
}