

@media (max-width: 769px) {
    .ant-typography {
        font-size: 95% !important;
    }

    .ant-table,
    .ant-tag {
        font-size: 60% !important;
    }

    .ant-space {
        display: flex;
        justify-content: end;
        width: fit-content !important;
    }

    .banner-img {
        width: 100% !important;
    }

    .ant-picker {
        width: 100% !important;
        padding: 0 3% !important;
    }

    .ant-picker-input input::placeholder {
        font-size: 70% !important;
    }

    .ant-space-item .ant-select {
        width: 100% !important;
        padding: 0 1% !important;
    }

    .ant-space-item .ant-select .ant-select-selection-search-input,
    .ant-space-item .ant-select .ant-select-selection-placeholder,
    .ant-space-item .ant-select .ant-select-arrow {
        font-size: 70% !important;
    }

    .view-page-logo {
        width: 10vw !important;
        padding: 50% !important;
    }
}

@media screen and (max-width: 540px) {

    .contract-container,
    .contract-container .ant-row,
    .contract-container .ant-col,
    .contract-container .ant-typography {
        font-size: 60% !important;
    }
}

@media (max-width: 530px) {
    .ant-segmented-item-label {
        font-size: 50% !important;
    }

    .segment-container {
        display: flex !important;
        justify-content: center !important;
    }

    .view-btn {
        width: 20% !important;
        font-size: 60% !important;
    }

    .avatar-editor-image {
        transform: scale(0.7) !important;
    }

    .view-page .ant-card-body .ant-row .ant-col {
        font-size: 70% !important;
    }


    .payslip-logo {
        width: 25% !important;
        margin-top: 5% !important;
    }
}

@media (max-width: 431px) {

    .ant-table-pagination-right li {
        font-size: 70% !important;
        transform: scale(0.8);
    }

    .ant-table-content {
        overflow-x: scroll !important;
    }

    .view-page {
        width: 100% !important;
    }

    .form-content {
        padding: 2% !important;
    }

    .ant-form-item-explain-error {
        font-size: 70%;
    }

    .main-content {
        padding-top: 3%;
    }

    .view-payslip-container .table,
    .view-payslip-container .ant-row {
        font-size: 80% !important;
    }

    .view-page-logo {
        width: 15vw !important;
        padding: 50% !important;
    }

    .view-page .ant-row,
    .view-page .ant-col,
    .view-page .ant-row h4 {
        font-size: 80%;
    }

    .ant-modal-content {
        max-height: 60vh !important;
        overflow-y: auto !important;
        -webkit-overflow-scrolling: touch;
    }

    .ant-form-item .ant-btn {
        margin-top: 10% !important;
    }

    .print-report-page .table {
        max-width: 100%;
    }

    .stripe-table {
        font-size: 50% !important;
    }

    .ant-form-item .ant-row .ant-form-item-label {
        padding-bottom: 0 !important;
    }
}

@media (max-width: 391px) {
    .form-content {
        padding: 10% !important;
    }
}

@media (max-width: 376px) {
    .segment-container {
        margin: 0px 5px !important;
    }

    .ant-btn {
        font-size: 80% !important;
    }


    .payslip-logo {
        width: 15% !important;
        margin-top: 5% !important;
    }
}

@media (max-width: 279px) {
    .ant-typography {
        font-size: 80% !important;
    }
}